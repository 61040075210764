:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: none;
  font-family: Arial, Helvetica, sans-serif;
  cursor: none;
}

/* -------------------- cursor ---------------------- */

.cursor {
  width: 2rem;
  height: 2rem;
  border: 2px dashed rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  animation: rotate 5s linear infinite;
  pointer-events: none;
  z-index: 100000;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor_follower {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  transition: all 0.2s ease-out;
  background-color: #ffffff17;
  backdrop-filter: blur(10px);
  z-index: 100000;
}

/* -------------------- cursor ---------------------- */

body {
  overflow-x: hidden;
  cursor: none;
}

a {
  font-weight: 500;
  /* color: #646cff; */
  text-decoration: inherit;
}

.loading-screen {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background-color: #353535;
  width: 0%;
  height: 100%;
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

#app {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  /* cursor: pointer; */
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}
